import { graphql } from "gatsby";
import { flatten, get, kebabCase, startCase } from "lodash";
import React, { Component } from "react";
import styled from "styled-components";
import { A, H2, H5, HR, IconLinkedIn, IconTwitter, P, Small } from "@upsolve/ui";
import { EVENTS } from "@upsolve/shared";
import { track } from "../components/analytics/track";
import * as CONTENT_CREATION_METHODS from "../components/analytics/contentCreationMethods";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import ImageInContext from "../components/Media/ImageInContext";
import LearnArticleTile from "../components/Learn/LearnArticleTile";
import PageTile from "../components/Layout/PageTile";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import Page from "../components/Layout/Page.div";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import authorToJSONLD from "../components/SEO/authorToJSONLD";
import BreadcrumbNav from "../components/Layout/BreadcrumbNav";
import {
  CITY_PAGE,
  CITY_SUB_PAGE,
  STATE_MEANS_TEST,
  STATE_BANKRUPTCY_FORMS,
} from "../components/Programmatic/pageTypes";

const contentGroup = CONTENT_GROUPS.AUTHOR_PROFILE;
const templateProps = {
  pageComponentName: "AuthorProfile",
  pageComponentVersion: 1,
};
const trackingPageContext = {
  contentGroup,
  contentCreationMethod: CONTENT_CREATION_METHODS.PROGRAMMATIC,
  ...templateProps,
};

/**
 * AuthorProfile
 * - v0: Basic profile + listing
 * - v1: Adjusted layout and added filter buttons
 * - v2: Added filter for city-level pages
 * - v3: Filter out articles with 404s or redirects
 *
 * @component
 * @version 3
 */
export default class AuthorProfile extends Component {
  state = {
    filterTag: null,
  };

  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }

  render() {
    const { filterTag } = this.state;
    const { author, allSitePage } = this.props.data;

    // Create a set of valid article paths
    const validArticlePaths = new Set();
    allSitePage.edges.forEach(({ node }) => {
      if (node.path.startsWith("/learn/") && node.path !== "/learn/") {
        // Extract the slug from the path
        const slug = node.path.replace("/learn/", "").replace(/\/$/, "");
        validArticlePaths.add(slug);
      }
    });

    // Filter articles to only show ones with real pages that exist in the site
    // HOW THIS PREVENTS 404s AND REDIRECTS:
    // 1. During build (gatsby-node.esm.js), some articles are skipped or given redirects
    //    instead of real pages due to invalid content, conflicts, or other issues
    // 2. allSitePage ONLY contains pages that were actually created during build
    //    (it doesn't include articles that were redirected or skipped)
    // 3. By filtering Contentful articles against allSitePage, we ensure we only
    //    display links to articles that have real pages in the final site build
    // 4. Result: No links to 404s or redirects will be shown to users
    const validArticles = (author.learnArticles || []).filter(
      (article) => article.slug && validArticlePaths.has(article.slug)
    );

    // Get tags from valid articles only
    const tags = Array.from(new Set(flatten(validArticles.map((article) => (article.tags || []).map((t) => t.value)))));

    return (
      <TopLevelLayout>
        <SEO
          title={`${author.name} - ${author.role} | Upsolve`}
          description={get(author, "biography.biography") || `${author.name} is a contributor for Upsolve.`}
          image={get(author, "image.image.file.url") || "https://upsolve.org/images/meta-legalaid.png"}
          url={`https://upsolve.org/authors/${author.slug}/`}
          jsonLD={[
            {
              "@context": "http://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@context": "http://schema.org",
                  "@type": "ListItem",
                  position: 1,
                  name: "Learn",
                  item: "https://upsolve.org/authors/",
                },
                {
                  "@context": "http://schema.org",
                  "@type": "ListItem",
                  position: 2,
                  name: author.name,
                  item: `https://upsolve.org/authors/${kebabCase(author.name).toLowerCase()}/`,
                },
              ],
            },
            authorToJSONLD(author),
          ]}
        />
        <MainHeader trackingPageContext={trackingPageContext} />
        <article>
          <Page>
            <BreadcrumbNav
              breadcrumbs={[
                { slug: "/authors", title: "Authors" },
                { slug: `/authors/${author.slug}/`, title: author.name },
              ]}
            />
            <AuthorProfileHeader>
              <div className="header">
                <div className="picture">
                  {get(author, "image.image.file.url") != null && (
                    <div className="headshot">
                      <ImageInContext alt={author.name} src={get(author, "image.image.file.url")} />
                    </div>
                  )}
                </div>
                <div className="text">
                  <H2 as="h1">{author.name}</H2>
                  <H5 as="h2">{author.role}</H5>
                  <div className="profiles">
                    <P>
                      {(get(author, "profiles") || []).map((p) => (
                        <A key={p.label} href={p.url} target="_blank" rel="noreferrer noopener">
                          {p.label === "LinkedIn" && <IconLinkedIn />}
                          {p.label === "Twitter" && <IconTwitter />} {p.label}
                        </A>
                      ))}
                    </P>
                  </div>
                </div>
              </div>
              {get(author, "biography.biography") != null && <P>{author.biography.biography}</P>}
            </AuthorProfileHeader>
            <HR />
            <AuthorContentSection>
              <div className="filter">
                <span
                  key="all"
                  className={filterTag == null ? "active" : ""}
                  onClick={() => this.setState({ filterTag: null })}
                >
                  All Articles
                </span>
                {tags.sort().map((tag) => (
                  <span
                    className={filterTag === tag ? "active" : ""}
                    key={tag}
                    onClick={() => this.setState({ filterTag: tag })}
                  >
                    {startCase(tag)}
                  </span>
                ))}
              </div>
              <div className="articles">
                <P>Articles written by {author.name}</P>
                {validArticles
                  .filter((article) => (filterTag ? (article.tags || []).some((t) => t.value === filterTag) : true))
                  .map((article) => (
                    <LearnArticleTile article={article} />
                  ))}
                {(author.pages || [])
                  .filter((page) => {
                    // we want to not have any articles listed on our site that no longer exist so that our site appears well taken care of and hence trustworthy to Google
                    const isCityRelatedPage =
                      page.type === CITY_PAGE ||
                      page.type === CITY_SUB_PAGE ||
                      page.type === STATE_MEANS_TEST ||
                      page.type === STATE_BANKRUPTCY_FORMS;
                    return !isCityRelatedPage;
                  })
                  .map((page) => (
                    <PageTile page={page} />
                  ))}
              </div>
            </AuthorContentSection>
          </Page>
        </article>
        <MainFooter />
      </TopLevelLayout>
    );
  }
}

const AuthorContentSection = styled.div`
  .filter,
  .articles {
    & > p {
      text-align: center;
      font-weight: 500;
      color: ${(props) => props.theme.colors.white[100]};
    }
  }
  .filter {
    width: 100%;
    max-width: 960px;
    margin: 1em auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    span {
      padding: 0.25em 0.75em;
      margin: 0.25em 0.5em;
      min-width: 160px;
      width: 160px;
      cursor: pointer;
      text-align: center;
      border-radius: 24px;
      border: 1px solid ${(props) => props.theme.colors.white[100]};
      font-size: 12px;
    }
    .active {
      color: ${(props) => props.theme.colors.brand[500]};
      border: 1px solid ${(props) => props.theme.colors.brand[500]};
    }
  }
  .articles {
  }
`;

const AuthorProfileHeader = styled.div`
  margin: 1em 0;
  .header {
    display: flex;
    .picture {
      width: 160px;
      margin: 2em 0 1em;
      .headshot {
        height: 120px;
        width: 120px;
        margin: 1em 0 0.5em;
        border-radius: 36px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 2.5em 0 0.5em;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      flex-direction: column;
      .picture {
        margin: 0.5em 1em;
      }
      .text {
        margin: 0.5em 1em;
      }
    }
  }
  small {
    margin-top: 0.5em;
  }
  .profiles {
    margin: 0;
    a {
      font-size: 13px;
      margin: 0 0.75em 0 0;
    }
    svg {
      height: 14px;
      width: 14px;
      margin-right: 4px;
      path {
        fill: ${(props) => props.theme.colors.brand[500]};
      }
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
    & > p {
      margin: 0 1em;
    }
  }
`;

/* eslint no-undef: "off"*/
export const pageQuery = graphql`
  query AuthorProfile($authorId: String) {
    author: contentfulAuthor(id: { eq: $authorId }) {
      ...AuthorNode
      learnArticles: learn_article {
        createdAt
        updatedAt
        slug
        title
        author {
          ...AuthorNode
        }
        authors {
          ...AuthorNode
        }
        tags {
          value
          label
          type
          hasDirectoryPage
        }
        articleExcerpt {
          articleExcerpt
        }
      }
      pages: page {
        type
        locality {
          level
          state
          city
        }
        createdAt
        updatedAt
        author {
          ...AuthorNode
        }
        authors {
          ...AuthorNode
        }
        path
        title
        summary {
          json
        }
      }
    }
    allSitePage(filter: { component: { regex: "/LearnArticle/" }, path: { regex: "//learn/[^/]+/$/" } }) {
      edges {
        node {
          path
        }
      }
    }
  }
`;
